// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-text .MuiDataGrid-cell {
    color: black;
    text-align: center;
  }
  
  .page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .centered-text {
    color: black;
    text-align: center;
  }
  
  .centered-text .MuiDataGrid-cell,
  .data-grid .MuiDataGrid-columnHeaderTitle { /* Add this selector to target column headers */
    color: black;
    text-align: center;
  }
  .grid-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
  }
  
  .zoom-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .zoom-controls button {
    margin: 0 0.5rem;
  }
/* ... (existing CSS code) */

.nodes-by-country {
  color: black;
  margin-top: 2rem;
  max-height: 400px;
  overflow-y: auto;
}

.nodes-by-country ul {
  list-style: none;
  padding: 0;
}

.nodes-by-country li {
  margin-bottom: 5px;
}
  
  /* ... (rest of the existing CSS code) */
  `, "",{"version":3,"sources":["webpack://./src/pages/NodesPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB;;EAEA;8CAC4C,+CAA+C;IACzF,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;AACF,4BAA4B;;AAE5B;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;EAEE,wCAAwC","sourcesContent":[".centered-text .MuiDataGrid-cell {\n    color: black;\n    text-align: center;\n  }\n  \n  .page-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n  }\n  \n  .centered-text {\n    color: black;\n    text-align: center;\n  }\n  \n  .centered-text .MuiDataGrid-cell,\n  .data-grid .MuiDataGrid-columnHeaderTitle { /* Add this selector to target column headers */\n    color: black;\n    text-align: center;\n  }\n  .grid-container {\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 1rem;\n  }\n  \n  .zoom-controls {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 1rem;\n  }\n  \n  .zoom-controls button {\n    margin: 0 0.5rem;\n  }\n/* ... (existing CSS code) */\n\n.nodes-by-country {\n  color: black;\n  margin-top: 2rem;\n  max-height: 400px;\n  overflow-y: auto;\n}\n\n.nodes-by-country ul {\n  list-style: none;\n  padding: 0;\n}\n\n.nodes-by-country li {\n  margin-bottom: 5px;\n}\n  \n  /* ... (rest of the existing CSS code) */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
